/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Sekciu pripravujeme"} description={"Sekciu pripravujeme"}>
        <Column className="--center" name={"0138lasohz6wi"} style={{"paddingTop":0,"paddingBottom":2,"backgroundColor":"rgba(0, 0, 0, 1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"<span style=\"color: var(--white);\"><a href=\"/\">Tribečské múzeum v Topoľčanoch</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1vzhpqr --parallax" name={"9pgfbudacmc"} style={{"marginTop":0,"paddingTop":20,"paddingBottom":0}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim3" anim={"3"} style={{"maxWidth":320}}>
              
              <Button className="btn-box btn-box--style2 btn-box--center ff--3 fs--14" use={"page"} href={"/"} style={{"maxWidth":796}} content={"<span style=\"font-weight: bold;\">ÚVOD</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1vzhpqr --center --parallax" name={"bmtoopxw4mn"} style={{"paddingTop":0,"marginBottom":0,"paddingBottom":126}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --left" anim={"6"}>
              
              <Title className="title-box title-box--center ff--3 fs--36" style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} content={"<span style=\"font-weight: bold; color: rgb(192, 192, 192);\">Sekciu pripravujeme</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1vzhpqr --center --parallax" name={"bmtoopxw4mn"} style={{"marginTop":0,"paddingTop":26,"paddingBottom":0}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/cfdb436a60a0427e827ab0024f2831af_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim3 --anim-s7 --left" anim={"3"} animS={"7"}>
              
              <Title className="title-box title-box--center ff--3 fs--36" content={"<span style=\"font-weight: bold; color: rgb(192, 192, 192);\">Mohlo by vás zaujímať\n</span>"}>
              </Title>

              <Image src={"https://cdn.swbpg.com/t/7746/201d91768e1e4041ba2da89422fd98e8_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":83,"marginTop":58,"paddingTop":0}} srcSet={"https://cdn.swbpg.com/t/7746/201d91768e1e4041ba2da89422fd98e8_s=350x_.png 350w, https://cdn.swbpg.com/t/7746/201d91768e1e4041ba2da89422fd98e8_s=660x_.png 660w, https://cdn.swbpg.com/t/7746/201d91768e1e4041ba2da89422fd98e8_s=860x_.png 860w, https://cdn.swbpg.com/t/7746/201d91768e1e4041ba2da89422fd98e8_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-stiglu css-1h6d8t4 --parallax pb--60 pt--60" name={"vystavy"} style={{"marginTop":0}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/0b8838ca480447ba8572adf595d6616c_e=0x106x2544x1272__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/0b8838ca480447ba8572adf595d6616c_e=0x106x2544x1272__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/0b8838ca480447ba8572adf595d6616c_e=0x106x2544x1272__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/0b8838ca480447ba8572adf595d6616c_e=0x106x2544x1272__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/0b8838ca480447ba8572adf595d6616c_e=0x106x2544x1272__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/0b8838ca480447ba8572adf595d6616c_e=0x106x2544x1272__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/0b8838ca480447ba8572adf595d6616c_e=0x106x2544x1272__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/0b8838ca480447ba8572adf595d6616c_e=0x106x2544x1272__s=3000x_.jpg);
    }
  
background-position: 50% 9%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box fs--22 btn-box--invert" use={"page"} href={"/muzeum-online"} target={null} content={"<span style=\"font-weight: 700;\">VÝSTAVY</span><br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-gdpoo2 --parallax pb--60 pt--60" name={"blog-muzeum-online"} style={{"marginTop":36}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/c21ec3d58f7f4f8c886591506b8203f0_e=515x146x1555x1554__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/c21ec3d58f7f4f8c886591506b8203f0_e=515x146x1555x1554__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/c21ec3d58f7f4f8c886591506b8203f0_e=515x146x1555x1554__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/c21ec3d58f7f4f8c886591506b8203f0_e=515x146x1555x1554__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/c21ec3d58f7f4f8c886591506b8203f0_e=515x146x1555x1554__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/c21ec3d58f7f4f8c886591506b8203f0_e=515x146x1555x1554__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/c21ec3d58f7f4f8c886591506b8203f0_e=515x146x1555x1554__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/c21ec3d58f7f4f8c886591506b8203f0_e=515x146x1555x1554__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box fs--22 btn-box--invert" use={"page"} href={"/muzeum-online#clanok"} content={"<span style=\"font-weight: 700;\">ČLÁNKY</span><br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-glj9p2 css-1ukpowb --parallax pb--60 pt--60" name={"predmet-mesiaca"} style={{"marginTop":37,"marginBottom":32}} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7746/11908972e06a4703837bd92c8a3f2761_s=3000x_.png);
    }
  
background-position: 50% 1%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box fs--22 btn-box--invert" use={"page"} href={"/predmet-mesiaca"} target={null} content={"<span style=\"font-weight: bold;\">PREDMET MESIACA</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}